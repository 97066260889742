import Route from "../utils/router/route";
import MyRouter from "../utils/router/router";
import AuthHandler from "./auth/handler";
import Login from "./auth/login";
import HomeScreen from "./homescreen";

const Main = (props) => {
    return <>
        <MyRouter>
            <Route path="/" element={
                <AuthHandler>
                    <HomeScreen />
                </AuthHandler>
            }></Route>
            <Route path="/login" element={<Login />}></Route>
        </MyRouter>
    </>;
};

export default Main;