import { useEffect, useState } from "react";


const Router = (props) => {
    const [path, setPath] = useState(window.location.pathname);

    useEffect(() => {
        window.addEventListener("popstate", (ev) => {
            setPath(window.location.pathname);
            window.removeEventListener("popstate", () => { });
        });
    }, [path]);

    const matchUrl = (child) => { if (child.props.path === path) return child; };
    const child = props.children.filter(matchUrl)[0];

    return child.props.element;
}

export default Router;
