import jwtDecode from "jwt-decode";

const checkJwt = () => {
    const token = localStorage.getItem("jwt");
    if (token === undefined) {
        return false;
    }

    try {
        const payload = jwtDecode(token);
        return payload;
    }
    catch {
        return false;
    }
};

export default checkJwt;