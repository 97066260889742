/* eslint-disable no-undef */
import jwtDecode from "jwt-decode";
import { useRef, useState, useEffect } from "react";
import Button from "../../components/form/button";
import Form from "../../components/form/form";
import Textfield from "../../components/form/texfield";
import { SnackBar, showSnackBar } from "../../components/global/snackbar";
import validateEmail from "./validations/email";

const Login = (props) => {
    const counter = useRef();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [buttonState, setButtonState] = useState("neutral");
    const [description, setDescription] = useState("");
    const [token, setToken] = useState(null);

    const onEmailChange = (event) => {
        const element = event.target;
        const email = element.value;

        setEmail(email);

        if (!validateEmail(email) && email.length !== 0) {
            element.classList.add("errored");
            setButtonState("neutral");
        }
        else {
            element.classList.remove("errored");
        }
    };

    useEffect(() => {
        turnstile.render('#cf', {
            sitekey: '0x4AAAAAAAMO2bbi-hLX2YoK',
            callback: function (token) {
                setToken(token);
            },
        });
    }, []);

    const onPasswordChange = (event) => {
        const element = event.target;
        const password = element.value;

        setPassword(password);

        counter.current.innerText = password.length;

        if (password.length < 8 && password.length !== 0) {
            element.classList.add("errored");
            setButtonState("neutral");
        }
        else {
            element.classList.remove("errored");
        }
    };

    const onLogin = (event) => {
        const emailElement = document.getElementById("email");
        const passwordElement = document.getElementById("password");
        if (!validateEmail(email)) {
            if (!emailElement.classList.contains("errored")) {
                emailElement.classList.add("errored");
            }
            setButtonState("errored");
            return;
        }
        if (password.length < 8) {
            if (!passwordElement.classList.contains("errored")) {
                passwordElement.classList.add("errored");
            }
            setButtonState("errored");
            return;
        }

        if (token == null) {
            setDescription("complete the captcha");
            showSnackBar({ location: "right" });
            return;
        }

        fetch('https://lansity.com/users/login', {
            method: 'post',
            body: JSON.stringify({ "email": email, "password": password, "cloudfareToken": token }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((res) => res.text())
            .then(async (text) => {
                console.log(text);

                try {
                    const payload = await jwtDecode(text);
                    console.log(payload);
                }
                catch (er) {
                    const json = JSON.parse(text);
                    setDescription(json.res);
                    showSnackBar({ location: "right" });
                }
            });
    };
    return <>
        <SnackBar description={description} />
        <div className="form-main-container">
            <Form title="LOGIN">
                <Textfield id="email" inputType="email" placeholder="enter email"
                    label="email"
                    onChange={onEmailChange}
                />
                <Textfield id="password" inputType="password" placeholder="enter password"
                    label="password"
                    onChange={onPasswordChange}>
                    <div className="counter" ref={counter}>0</div>
                </Textfield>
                <div id="cf" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}></div>
                <Button value="LOGIN" state={buttonState}
                    onClick={onLogin}
                />
            </Form>
        </div >
    </>;
};

export default Login;