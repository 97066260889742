import React from "react";
import checkJwt from "./jwt";
import Login from "./login";

const AuthHandler = (props) => {

    let payload = checkJwt();

    if (typeof (payload) == 'boolean') {
        window.history.replaceState("", "", "/login");
        return <Login />;
    }

    let child = props.children;

    if (React.isValidElement(child)) {
        child = React.cloneElement(child, { payload });
    }

    return child;
};

export default AuthHandler;