const Textfield = (props) => {
    const className = props.className !== undefined ? "auth-textfield " + props.className : "auth-textfield";
    return <>
        <div className="outer-textfield-container">
            <p className="textfield-label">{props.label}</p>
            <input spellCheck="false" className={className}
                id={props.id}
                value={props.intialValue}
                placeholder={props.hintText}
                type={props.inputType}
                onSubmit={props.onSubmit}
                onChange={props.onChange}>
            </input>
            {props.children}
        </div>
    </>;
};

export default Textfield;