let timeout;

export const SnackBar = ({ description }) => {
    return <>
        <div className="snack-outer-container">
            <div className="snack-main-container">
                <div className="snack-contents">
                    {description}
                    <div className="snack-close-icon" onClick={(element) => {
                        const snackbar = document.getElementsByClassName("snack-main-container")[0];
                        const progress = document.getElementsByClassName("snack-progress")[0];

                        clearTimeout(timeout);

                        snackbar.classList.remove("active");
                        snackbar.classList.add("inactive");

                        setTimeout(() => {
                            progress.classList.remove("active");
                        }, 500);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.3rem" width="1.3rem" viewBox="0 0 384 512">
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                        </svg>
                    </div>
                </div>
                <div className="snack-progress" />
            </div>
        </div>
    </>;
};

export const showSnackBar = ({ location }) => {
    const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const snackOuterContainer = document.getElementsByClassName("snack-main-container")[0];

    if (width < 450) {
        snackOuterContainer.classList.remove("right");
        snackOuterContainer.classList.remove("left");
        snackOuterContainer.classList.add("center");
    }
    else {
        if (location === 'left') {
            snackOuterContainer.classList.remove("right");
            snackOuterContainer.classList.add("left");
            snackOuterContainer.classList.remove("center");
        }
        else if (location === 'right') {
            snackOuterContainer.classList.add("right");
            snackOuterContainer.classList.remove("left");
            snackOuterContainer.classList.remove("center");
        }
        else if (location === 'center') {
            snackOuterContainer.classList.remove("right");
            snackOuterContainer.classList.remove("left");
            snackOuterContainer.classList.add("center");
        }
    }

    start();
};

const start = () => {
    const snackbar = document.getElementsByClassName("snack-main-container")[0];
    const progress = document.getElementsByClassName("snack-progress")[0];

    snackbar.classList.remove("inactive");
    snackbar.classList.add("active");
    progress.classList.add("active");
    timeout = setTimeout(() => {
        snackbar.classList.add("inactive");
        snackbar.classList.remove("active");
        progress.classList.remove("active");
    }, 7000);
};