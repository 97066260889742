const Button = (props) => {
    return <>
        <input className={"auth-button " + props.state}
            type="button"
            onClick={props.onClick}
            value={props.value}
        />
    </>;
};

export default Button;